// Generated by purs bundle 0.12.3
var PS = {};

(function (exports) {
  "use strict";

  exports.unit = {};
})(PS["Data.Unit"] = PS["Data.Unit"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Data.Unit"];
  var Data_Show = PS["Data.Show"];
  exports["unit"] = $foreign.unit;
})(PS["Data.Unit"] = PS["Data.Unit"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Data.Functor"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Function = PS["Data.Function"];
  var Data_Unit = PS["Data.Unit"];

  var Functor = function Functor(map) {
    this.map = map;
  };

  var map = function map(dict) {
    return dict.map;
  };

  exports["Functor"] = Functor;
  exports["map"] = map;
})(PS["Data.Functor"] = PS["Data.Functor"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Control.Apply"];
  var Control_Category = PS["Control.Category"];
  var Data_Function = PS["Data.Function"];
  var Data_Functor = PS["Data.Functor"];

  var Apply = function Apply(Functor0, apply) {
    this.Functor0 = Functor0;
    this.apply = apply;
  };

  var apply = function apply(dict) {
    return dict.apply;
  };

  exports["Apply"] = Apply;
  exports["apply"] = apply;
})(PS["Control.Apply"] = PS["Control.Apply"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Apply = PS["Control.Apply"];
  var Data_Functor = PS["Data.Functor"];
  var Data_Unit = PS["Data.Unit"];

  var Applicative = function Applicative(Apply0, pure) {
    this.Apply0 = Apply0;
    this.pure = pure;
  };

  var pure = function pure(dict) {
    return dict.pure;
  };

  var liftA1 = function liftA1(dictApplicative) {
    return function (f) {
      return function (a) {
        return Control_Apply.apply(dictApplicative.Apply0())(pure(dictApplicative)(f))(a);
      };
    };
  };

  exports["Applicative"] = Applicative;
  exports["pure"] = pure;
  exports["liftA1"] = liftA1;
})(PS["Control.Applicative"] = PS["Control.Applicative"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Control.Bind"];
  var Control_Applicative = PS["Control.Applicative"];
  var Control_Apply = PS["Control.Apply"];
  var Control_Category = PS["Control.Category"];
  var Data_Function = PS["Data.Function"];
  var Data_Functor = PS["Data.Functor"];
  var Data_Unit = PS["Data.Unit"];

  var Bind = function Bind(Apply0, bind) {
    this.Apply0 = Apply0;
    this.bind = bind;
  };

  var bind = function bind(dict) {
    return dict.bind;
  };

  exports["Bind"] = Bind;
  exports["bind"] = bind;
})(PS["Control.Bind"] = PS["Control.Bind"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Applicative = PS["Control.Applicative"];
  var Control_Apply = PS["Control.Apply"];
  var Control_Bind = PS["Control.Bind"];
  var Data_Functor = PS["Data.Functor"];
  var Data_Unit = PS["Data.Unit"];

  var Monad = function Monad(Applicative0, Bind1) {
    this.Applicative0 = Applicative0;
    this.Bind1 = Bind1;
  };

  var ap = function ap(dictMonad) {
    return function (f) {
      return function (a) {
        return Control_Bind.bind(dictMonad.Bind1())(f)(function (v) {
          return Control_Bind.bind(dictMonad.Bind1())(a)(function (v1) {
            return Control_Applicative.pure(dictMonad.Applicative0())(v(v1));
          });
        });
      };
    };
  };

  exports["Monad"] = Monad;
  exports["ap"] = ap;
})(PS["Control.Monad"] = PS["Control.Monad"] || {});

(function (exports) {
  "use strict";

  exports.runFn2 = function (fn) {
    return function (a) {
      return function (b) {
        return fn(a, b);
      };
    };
  };
})(PS["Data.Function.Uncurried"] = PS["Data.Function.Uncurried"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Data.Function.Uncurried"];
  var Data_Unit = PS["Data.Unit"];
  exports["runFn2"] = $foreign.runFn2;
})(PS["Data.Function.Uncurried"] = PS["Data.Function.Uncurried"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Alt = PS["Control.Alt"];
  var Control_Alternative = PS["Control.Alternative"];
  var Control_Applicative = PS["Control.Applicative"];
  var Control_Apply = PS["Control.Apply"];
  var Control_Bind = PS["Control.Bind"];
  var Control_Category = PS["Control.Category"];
  var Control_Extend = PS["Control.Extend"];
  var Control_Monad = PS["Control.Monad"];
  var Control_MonadZero = PS["Control.MonadZero"];
  var Control_Plus = PS["Control.Plus"];
  var Data_Bounded = PS["Data.Bounded"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Function = PS["Data.Function"];
  var Data_Functor = PS["Data.Functor"];
  var Data_Functor_Invariant = PS["Data.Functor.Invariant"];
  var Data_Monoid = PS["Data.Monoid"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Ordering = PS["Data.Ordering"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Show = PS["Data.Show"];
  var Data_Unit = PS["Data.Unit"];
  var Prelude = PS["Prelude"];

  var Nothing = function () {
    function Nothing() {}

    ;
    Nothing.value = new Nothing();
    return Nothing;
  }();

  var Just = function () {
    function Just(value0) {
      this.value0 = value0;
    }

    ;

    Just.create = function (value0) {
      return new Just(value0);
    };

    return Just;
  }();

  exports["Nothing"] = Nothing;
  exports["Just"] = Just;
})(PS["Data.Maybe"] = PS["Data.Maybe"] || {});

(function (exports) {
  "use strict";

  exports.nullable = function (a, r, f) {
    return a == null ? r : f(a);
  };
})(PS["Data.Nullable"] = PS["Data.Nullable"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Data.Nullable"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Function = PS["Data.Function"];
  var Data_Function_Uncurried = PS["Data.Function.Uncurried"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Ord = PS["Data.Ord"];
  var Data_Show = PS["Data.Show"];
  var Prelude = PS["Prelude"];

  var toMaybe = function toMaybe(n) {
    return $foreign.nullable(n, Data_Maybe.Nothing.value, Data_Maybe.Just.create);
  };

  exports["toMaybe"] = toMaybe;
})(PS["Data.Nullable"] = PS["Data.Nullable"] || {});

(function (exports) {
  "use strict";

  exports.pureE = function (a) {
    return function () {
      return a;
    };
  };

  exports.bindE = function (a) {
    return function (f) {
      return function () {
        return f(a())();
      };
    };
  };
})(PS["Effect"] = PS["Effect"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Effect"];
  var Control_Applicative = PS["Control.Applicative"];
  var Control_Apply = PS["Control.Apply"];
  var Control_Bind = PS["Control.Bind"];
  var Control_Monad = PS["Control.Monad"];
  var Data_Functor = PS["Data.Functor"];
  var Data_Monoid = PS["Data.Monoid"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Prelude = PS["Prelude"];
  var monadEffect = new Control_Monad.Monad(function () {
    return applicativeEffect;
  }, function () {
    return bindEffect;
  });
  var bindEffect = new Control_Bind.Bind(function () {
    return applyEffect;
  }, $foreign.bindE);
  var applyEffect = new Control_Apply.Apply(function () {
    return functorEffect;
  }, Control_Monad.ap(monadEffect));
  var applicativeEffect = new Control_Applicative.Applicative(function () {
    return applyEffect;
  }, $foreign.pureE);
  var functorEffect = new Data_Functor.Functor(Control_Applicative.liftA1(applicativeEffect));
  exports["functorEffect"] = functorEffect;
  exports["applyEffect"] = applyEffect;
  exports["applicativeEffect"] = applicativeEffect;
  exports["bindEffect"] = bindEffect;
  exports["monadEffect"] = monadEffect;
})(PS["Effect"] = PS["Effect"] || {});

(function (exports) {
  "use strict";

  exports.log = function (s) {
    return function () {
      console.log(s);
      return {};
    };
  };
})(PS["Effect.Console"] = PS["Effect.Console"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Effect.Console"];
  var Data_Show = PS["Data.Show"];
  var Data_Unit = PS["Data.Unit"];
  var Effect = PS["Effect"];
  exports["log"] = $foreign.log;
})(PS["Effect.Console"] = PS["Effect.Console"] || {});

(function (exports) {
  "use strict";

  exports.error = function (msg) {
    return new Error(msg);
  };

  exports.throwException = function (e) {
    return function () {
      throw e;
    };
  };
})(PS["Effect.Exception"] = PS["Effect.Exception"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Effect.Exception"];
  var Control_Applicative = PS["Control.Applicative"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Either = PS["Data.Either"];
  var Data_Functor = PS["Data.Functor"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Show = PS["Data.Show"];
  var Effect = PS["Effect"];
  var Prelude = PS["Prelude"];

  var $$throw = function $$throw($1) {
    return $foreign.throwException($foreign.error($1));
  };

  exports["throw"] = $$throw;
})(PS["Effect.Exception"] = PS["Effect.Exception"] || {});

(function (exports) {
  "use strict";

  exports.runEffectFn3 = function runEffectFn3(fn) {
    return function (a) {
      return function (b) {
        return function (c) {
          return function () {
            return fn(a, b, c);
          };
        };
      };
    };
  };
})(PS["Effect.Uncurried"] = PS["Effect.Uncurried"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Effect.Uncurried"];
  var Effect = PS["Effect"];
  exports["runEffectFn3"] = $foreign.runEffectFn3;
})(PS["Effect.Uncurried"] = PS["Effect.Uncurried"] || {});

(function (exports) {
  var Typography = require('antd').Typography;

  exports._title = Typography.Title;
})(PS["Optimus.Common.Client.Antd.Typography"] = PS["Optimus.Common.Client.Antd.Typography"] || {});

(function (exports) {
  "use strict";

  var React = require("react");

  exports.createComponent = function () {
    // Begin component prototype functions
    // (`this`-dependent, defined outside `createComponent`
    // for a slight performance boost)
    function toSelf() {
      var instance = this;

      var setStateThen = function setStateThen(update) {
        return function (effects) {
          return function () {
            instance.setState(function (state) {
              return {
                $$state: update(state.$$state)
              };
            }, effects);
          };
        };
      };

      var self = {
        props: instance.props.$$props,
        state: instance.state === null ? null : instance.state.$$state,
        setState: function setState(update) {
          return setStateThen(update)(undefined);
        },
        setStateThen: setStateThen,
        instance_: instance
      };
      return self;
    }

    function componentDidMount() {
      var didMount = this.$$spec.didMount;

      if (didMount !== undefined) {
        didMount(this.toSelf())();
      }
    }

    function shouldComponentUpdate(nextProps, nextState) {
      var shouldUpdate = this.$$spec.shouldUpdate;
      return shouldUpdate === undefined ? true : shouldUpdate(this.toSelf())({
        nextProps: nextProps.$$props,
        nextState: nextState === null ? null : nextState.$$state
      });
    }

    function componentDidUpdate(prevProps, prevState) {
      var didUpdate = this.$$spec.didUpdate;

      if (didUpdate !== undefined) {
        didUpdate(this.toSelf())({
          prevProps: prevProps.$$props,
          prevState: prevState === null ? null : prevState.$$state
        })();
      }
    }

    function componentWillUnmount() {
      this.$$mounted = false;
      var willUnmount = this.$$spec.willUnmount;

      if (willUnmount !== undefined) {
        willUnmount(this.toSelf())();
      }
    }

    function render() {
      return this.$$spec.render(this.toSelf());
    } // End component prototype functions


    return function (displayName) {
      var Component = function constructor(props) {
        this.$$mounted = true;
        this.$$spec = props.$$spec;
        this.state = // React may optimize components with no state,
        // so we leave state null if it was left as
        // the default value.
        this.$$spec.initialState === undefined ? null : {
          $$state: this.$$spec.initialState
        };
        return this;
      };

      Component.displayName = displayName;
      Component.prototype = Object.create(React.Component.prototype);
      Component.prototype.constructor = Component;
      Component.prototype.toSelf = toSelf;
      Component.prototype.shouldComponentUpdate = shouldComponentUpdate;
      Component.prototype.componentDidMount = componentDidMount;
      Component.prototype.componentDidUpdate = componentDidUpdate;
      Component.prototype.componentWillUnmount = componentWillUnmount;
      Component.prototype.render = render;
      return Component;
    };
  }();

  exports.make = function (_unionDict) {
    return function ($$type) {
      return function ($$spec) {
        var $$specPadded = {
          initialState: $$spec.initialState,
          render: $$spec.render,
          didMount: $$spec.didMount,
          shouldUpdate: $$spec.shouldUpdate,
          didUpdate: $$spec.didUpdate,
          willUnmount: $$spec.willUnmount
        };
        return function ($$props) {
          var props = {
            $$props: $$props,
            $$spec: $$specPadded
          };
          return React.createElement($$type, props);
        };
      };
    };
  };

  exports.element_ = function (component, props) {
    return React.createElement.apply(null, [component, props].concat(props && props.children || null));
  };
})(PS["React.Basic"] = PS["React.Basic"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["React.Basic"];
  var Data_Function_Uncurried = PS["Data.Function.Uncurried"];
  var Data_Monoid = PS["Data.Monoid"];
  var Data_Nullable = PS["Data.Nullable"];
  var Data_Semigroup = PS["Data.Semigroup"];
  var Data_Unit = PS["Data.Unit"];
  var Effect = PS["Effect"];
  var Effect_Uncurried = PS["Effect.Uncurried"];
  var Prelude = PS["Prelude"];
  var Type_Row = PS["Type.Row"];
  var element = Data_Function_Uncurried.runFn2($foreign.element_);
  exports["element"] = element;
  exports["createComponent"] = $foreign.createComponent;
  exports["make"] = $foreign.make;
})(PS["React.Basic"] = PS["React.Basic"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Optimus.Common.Client.Antd.Typography"];
  var Effect = PS["Effect"];
  var Prelude = PS["Prelude"];
  var React_Basic = PS["React.Basic"];

  var title = function title(dictUnion) {
    return React_Basic.element($foreign["_title"]);
  };

  var title_ = function title_(children) {
    return title()({
      children: children
    });
  };

  exports["title"] = title;
  exports["title_"] = title_;
})(PS["Optimus.Common.Client.Antd.Typography"] = PS["Optimus.Common.Client.Antd.Typography"] || {});

(function (exports) {
  "use strict";

  var ReactDOM = require("react-dom");

  exports.render_ = function (jsx, node, callback) {
    ReactDOM.render(jsx, node, callback);
  };
})(PS["React.Basic.DOM"] = PS["React.Basic.DOM"] || {});

(function (exports) {
  "use strict"; // module Unsafe.Coerce

  exports.unsafeCoerce = function (x) {
    return x;
  };
})(PS["Unsafe.Coerce"] = PS["Unsafe.Coerce"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Unsafe.Coerce"];
  exports["unsafeCoerce"] = $foreign.unsafeCoerce;
})(PS["Unsafe.Coerce"] = PS["Unsafe.Coerce"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var React_Basic = PS["React.Basic"];
  var React_Basic_Events = PS["React.Basic.Events"];
  var Unsafe_Coerce = PS["Unsafe.Coerce"];
  var unsafeCreateDOMComponent = Unsafe_Coerce.unsafeCoerce;
  exports["unsafeCreateDOMComponent"] = unsafeCreateDOMComponent;
})(PS["React.Basic.DOM.Internal"] = PS["React.Basic.DOM.Internal"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var React_Basic = PS["React.Basic"];
  var React_Basic_DOM_Internal = PS["React.Basic.DOM.Internal"];
  var React_Basic_Events = PS["React.Basic.Events"];

  var div = function div(dictUnion) {
    return React_Basic.element(React_Basic_DOM_Internal.unsafeCreateDOMComponent("div"));
  };

  var div_ = function div_(children) {
    return div()({
      children: children
    });
  };

  exports["div"] = div;
  exports["div_"] = div_;
})(PS["React.Basic.DOM.Generated"] = PS["React.Basic.DOM.Generated"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["React.Basic.DOM"];
  var Control_Applicative = PS["Control.Applicative"];
  var Control_Bind = PS["Control.Bind"];
  var Data_Either = PS["Data.Either"];
  var Data_Function_Uncurried = PS["Data.Function.Uncurried"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Nullable = PS["Data.Nullable"];
  var Data_Unit = PS["Data.Unit"];
  var Effect = PS["Effect"];
  var Effect_Exception = PS["Effect.Exception"];
  var Effect_Uncurried = PS["Effect.Uncurried"];
  var Prelude = PS["Prelude"];
  var React_Basic = PS["React.Basic"];
  var React_Basic_DOM_Generated = PS["React.Basic.DOM.Generated"];
  var React_Basic_DOM_Internal = PS["React.Basic.DOM.Internal"];
  var Unsafe_Coerce = PS["Unsafe.Coerce"];
  var Web_DOM = PS["Web.DOM"];
  var text = Unsafe_Coerce.unsafeCoerce;
  var render$prime = Effect_Uncurried.runEffectFn3($foreign.render_);

  var render = function render(jsx) {
    return function (node) {
      return render$prime(jsx)(node)(Control_Applicative.pure(Effect.applicativeEffect)(Data_Unit.unit));
    };
  };

  exports["render"] = render;
  exports["text"] = text;
})(PS["React.Basic.DOM"] = PS["React.Basic.DOM"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Optimus_Common_Client_Antd = PS["Optimus.Common.Client.Antd"];
  var Optimus_Common_Client_Antd_Typography = PS["Optimus.Common.Client.Antd.Typography"];
  var Prelude = PS["Prelude"];
  var React_Basic = PS["React.Basic"];
  var React_Basic_DOM = PS["React.Basic.DOM"];
  var React_Basic_DOM_Generated = PS["React.Basic.DOM.Generated"];
  var component = React_Basic.createComponent("App");

  var app = function () {
    var render = function render(v) {
      return React_Basic_DOM_Generated.div_([Optimus_Common_Client_Antd_Typography.title_([React_Basic_DOM.text("This is the ID Module")])]);
    };

    var initialState = {};
    return React_Basic.make()(component)({
      initialState: initialState,
      render: render
    });
  }();

  exports["app"] = app;
})(PS["Optimus.Modules.Id.Client.App"] = PS["Optimus.Modules.Id.Client.App"] || {});

(function (exports) {
  "use strict";

  exports._getElementById = function (id) {
    return function (node) {
      return function () {
        return node.getElementById(id);
      };
    };
  };
})(PS["Web.DOM.NonElementParentNode"] = PS["Web.DOM.NonElementParentNode"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Web.DOM.NonElementParentNode"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Functor = PS["Data.Functor"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Nullable = PS["Data.Nullable"];
  var Effect = PS["Effect"];
  var Prelude = PS["Prelude"];
  var Web_DOM_Element = PS["Web.DOM.Element"];

  var getElementById = function getElementById(eid) {
    return function ($0) {
      return Data_Functor.map(Effect.functorEffect)(Data_Nullable.toMaybe)($foreign["_getElementById"](eid)($0));
    };
  };

  exports["getElementById"] = getElementById;
})(PS["Web.DOM.NonElementParentNode"] = PS["Web.DOM.NonElementParentNode"] || {});

(function (exports) {
  /* global window */
  "use strict";

  exports.window = function () {
    return window;
  };
})(PS["Web.HTML"] = PS["Web.HTML"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Web.HTML.HTMLDocument"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Functor = PS["Data.Functor"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Nullable = PS["Data.Nullable"];
  var Effect = PS["Effect"];
  var Prelude = PS["Prelude"];
  var Unsafe_Coerce = PS["Unsafe.Coerce"];
  var Web_DOM_Document = PS["Web.DOM.Document"];
  var Web_DOM_Internal_Types = PS["Web.DOM.Internal.Types"];
  var Web_DOM_NonElementParentNode = PS["Web.DOM.NonElementParentNode"];
  var Web_DOM_ParentNode = PS["Web.DOM.ParentNode"];
  var Web_Event_EventTarget = PS["Web.Event.EventTarget"];
  var Web_HTML_HTMLDocument_ReadyState = PS["Web.HTML.HTMLDocument.ReadyState"];
  var Web_HTML_HTMLElement = PS["Web.HTML.HTMLElement"];
  var Web_HTML_HTMLScriptElement = PS["Web.HTML.HTMLScriptElement"];
  var Web_Internal_FFI = PS["Web.Internal.FFI"];
  var toNonElementParentNode = Unsafe_Coerce.unsafeCoerce;
  exports["toNonElementParentNode"] = toNonElementParentNode;
})(PS["Web.HTML.HTMLDocument"] = PS["Web.HTML.HTMLDocument"] || {});

(function (exports) {
  "use strict";

  exports.document = function (window) {
    return function () {
      return window.document;
    };
  };
})(PS["Web.HTML.Window"] = PS["Web.HTML.Window"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Web.HTML.Window"];
  var Control_Semigroupoid = PS["Control.Semigroupoid"];
  var Data_Eq = PS["Data.Eq"];
  var Data_Functor = PS["Data.Functor"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Newtype = PS["Data.Newtype"];
  var Data_Nullable = PS["Data.Nullable"];
  var Data_Ord = PS["Data.Ord"];
  var Effect = PS["Effect"];
  var Prelude = PS["Prelude"];
  var Unsafe_Coerce = PS["Unsafe.Coerce"];
  var Web_Event_EventTarget = PS["Web.Event.EventTarget"];
  var Web_HTML_HTMLDocument = PS["Web.HTML.HTMLDocument"];
  var Web_HTML_History = PS["Web.HTML.History"];
  var Web_HTML_Location = PS["Web.HTML.Location"];
  var Web_HTML_Navigator = PS["Web.HTML.Navigator"];
  var Web_Storage_Storage = PS["Web.Storage.Storage"];
  exports["document"] = $foreign.document;
})(PS["Web.HTML.Window"] = PS["Web.HTML.Window"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var $foreign = PS["Web.HTML"];
  var Effect = PS["Effect"];
  var Web_HTML_HTMLAnchorElement = PS["Web.HTML.HTMLAnchorElement"];
  var Web_HTML_HTMLAreaElement = PS["Web.HTML.HTMLAreaElement"];
  var Web_HTML_HTMLAudioElement = PS["Web.HTML.HTMLAudioElement"];
  var Web_HTML_HTMLBRElement = PS["Web.HTML.HTMLBRElement"];
  var Web_HTML_HTMLBaseElement = PS["Web.HTML.HTMLBaseElement"];
  var Web_HTML_HTMLBodyElement = PS["Web.HTML.HTMLBodyElement"];
  var Web_HTML_HTMLButtonElement = PS["Web.HTML.HTMLButtonElement"];
  var Web_HTML_HTMLCanvasElement = PS["Web.HTML.HTMLCanvasElement"];
  var Web_HTML_HTMLDListElement = PS["Web.HTML.HTMLDListElement"];
  var Web_HTML_HTMLDataElement = PS["Web.HTML.HTMLDataElement"];
  var Web_HTML_HTMLDataListElement = PS["Web.HTML.HTMLDataListElement"];
  var Web_HTML_HTMLDivElement = PS["Web.HTML.HTMLDivElement"];
  var Web_HTML_HTMLDocument = PS["Web.HTML.HTMLDocument"];
  var Web_HTML_HTMLElement = PS["Web.HTML.HTMLElement"];
  var Web_HTML_HTMLEmbedElement = PS["Web.HTML.HTMLEmbedElement"];
  var Web_HTML_HTMLFieldSetElement = PS["Web.HTML.HTMLFieldSetElement"];
  var Web_HTML_HTMLFormElement = PS["Web.HTML.HTMLFormElement"];
  var Web_HTML_HTMLHRElement = PS["Web.HTML.HTMLHRElement"];
  var Web_HTML_HTMLHeadElement = PS["Web.HTML.HTMLHeadElement"];
  var Web_HTML_HTMLHeadingElement = PS["Web.HTML.HTMLHeadingElement"];
  var Web_HTML_HTMLIFrameElement = PS["Web.HTML.HTMLIFrameElement"];
  var Web_HTML_HTMLImageElement = PS["Web.HTML.HTMLImageElement"];
  var Web_HTML_HTMLInputElement = PS["Web.HTML.HTMLInputElement"];
  var Web_HTML_HTMLKeygenElement = PS["Web.HTML.HTMLKeygenElement"];
  var Web_HTML_HTMLLIElement = PS["Web.HTML.HTMLLIElement"];
  var Web_HTML_HTMLLabelElement = PS["Web.HTML.HTMLLabelElement"];
  var Web_HTML_HTMLLegendElement = PS["Web.HTML.HTMLLegendElement"];
  var Web_HTML_HTMLLinkElement = PS["Web.HTML.HTMLLinkElement"];
  var Web_HTML_HTMLMapElement = PS["Web.HTML.HTMLMapElement"];
  var Web_HTML_HTMLMediaElement = PS["Web.HTML.HTMLMediaElement"];
  var Web_HTML_HTMLMetaElement = PS["Web.HTML.HTMLMetaElement"];
  var Web_HTML_HTMLMeterElement = PS["Web.HTML.HTMLMeterElement"];
  var Web_HTML_HTMLModElement = PS["Web.HTML.HTMLModElement"];
  var Web_HTML_HTMLOListElement = PS["Web.HTML.HTMLOListElement"];
  var Web_HTML_HTMLObjectElement = PS["Web.HTML.HTMLObjectElement"];
  var Web_HTML_HTMLOptGroupElement = PS["Web.HTML.HTMLOptGroupElement"];
  var Web_HTML_HTMLOptionElement = PS["Web.HTML.HTMLOptionElement"];
  var Web_HTML_HTMLOutputElement = PS["Web.HTML.HTMLOutputElement"];
  var Web_HTML_HTMLParagraphElement = PS["Web.HTML.HTMLParagraphElement"];
  var Web_HTML_HTMLParamElement = PS["Web.HTML.HTMLParamElement"];
  var Web_HTML_HTMLPreElement = PS["Web.HTML.HTMLPreElement"];
  var Web_HTML_HTMLProgressElement = PS["Web.HTML.HTMLProgressElement"];
  var Web_HTML_HTMLQuoteElement = PS["Web.HTML.HTMLQuoteElement"];
  var Web_HTML_HTMLScriptElement = PS["Web.HTML.HTMLScriptElement"];
  var Web_HTML_HTMLSelectElement = PS["Web.HTML.HTMLSelectElement"];
  var Web_HTML_HTMLSourceElement = PS["Web.HTML.HTMLSourceElement"];
  var Web_HTML_HTMLSpanElement = PS["Web.HTML.HTMLSpanElement"];
  var Web_HTML_HTMLStyleElement = PS["Web.HTML.HTMLStyleElement"];
  var Web_HTML_HTMLTableCaptionElement = PS["Web.HTML.HTMLTableCaptionElement"];
  var Web_HTML_HTMLTableCellElement = PS["Web.HTML.HTMLTableCellElement"];
  var Web_HTML_HTMLTableColElement = PS["Web.HTML.HTMLTableColElement"];
  var Web_HTML_HTMLTableDataCellElement = PS["Web.HTML.HTMLTableDataCellElement"];
  var Web_HTML_HTMLTableElement = PS["Web.HTML.HTMLTableElement"];
  var Web_HTML_HTMLTableHeaderCellElement = PS["Web.HTML.HTMLTableHeaderCellElement"];
  var Web_HTML_HTMLTableRowElement = PS["Web.HTML.HTMLTableRowElement"];
  var Web_HTML_HTMLTableSectionElement = PS["Web.HTML.HTMLTableSectionElement"];
  var Web_HTML_HTMLTemplateElement = PS["Web.HTML.HTMLTemplateElement"];
  var Web_HTML_HTMLTextAreaElement = PS["Web.HTML.HTMLTextAreaElement"];
  var Web_HTML_HTMLTimeElement = PS["Web.HTML.HTMLTimeElement"];
  var Web_HTML_HTMLTitleElement = PS["Web.HTML.HTMLTitleElement"];
  var Web_HTML_HTMLTrackElement = PS["Web.HTML.HTMLTrackElement"];
  var Web_HTML_HTMLUListElement = PS["Web.HTML.HTMLUListElement"];
  var Web_HTML_HTMLVideoElement = PS["Web.HTML.HTMLVideoElement"];
  var Web_HTML_History = PS["Web.HTML.History"];
  var Web_HTML_Location = PS["Web.HTML.Location"];
  var Web_HTML_Navigator = PS["Web.HTML.Navigator"];
  var Web_HTML_Window = PS["Web.HTML.Window"];
  exports["window"] = $foreign.window;
})(PS["Web.HTML"] = PS["Web.HTML"] || {});

(function (exports) {
  // Generated by purs version 0.12.3
  "use strict";

  var Control_Bind = PS["Control.Bind"];
  var Data_Maybe = PS["Data.Maybe"];
  var Data_Unit = PS["Data.Unit"];
  var Effect = PS["Effect"];
  var Effect_Console = PS["Effect.Console"];
  var Effect_Exception = PS["Effect.Exception"];
  var Optimus_Modules_Id_Client_App = PS["Optimus.Modules.Id.Client.App"];
  var Prelude = PS["Prelude"];
  var React_Basic_DOM = PS["React.Basic.DOM"];
  var Web_DOM_NonElementParentNode = PS["Web.DOM.NonElementParentNode"];
  var Web_HTML = PS["Web.HTML"];
  var Web_HTML_HTMLDocument = PS["Web.HTML.HTMLDocument"];
  var Web_HTML_Window = PS["Web.HTML.Window"];

  var main = function __do() {
    var v = Web_HTML.window();
    var v1 = Web_HTML_Window.document(v)();
    var v2 = Web_DOM_NonElementParentNode.getElementById("root")(Web_HTML_HTMLDocument.toNonElementParentNode(v1))();

    (function () {
      if (v2 instanceof Data_Maybe.Nothing) {
        return Effect_Exception["throw"]("Container element not found")();
      }

      ;

      if (v2 instanceof Data_Maybe.Just) {
        return React_Basic_DOM.render(Optimus_Modules_Id_Client_App.app(Data_Unit.unit))(v2.value0)();
      }

      ;
      throw new Error("Failed pattern match at Optimus.Modules.Id.Client.Main (line 21, column 3 - line 23, column 34): " + [v2.constructor.name]);
    })();

    return Effect_Console.log("Optimus Started!")();
  };

  exports["main"] = main;
})(PS["Optimus.Modules.Id.Client.Main"] = PS["Optimus.Modules.Id.Client.Main"] || {});

module.exports = PS;